<template>
  <div class="box">
    <div class="const">
      <div class="text">
        <div class="text-title">商业合作 :</div>
        <div class="label">
          <span class="phone">电话: </span>
          <div>
            0571-87073809
          </div>
        
        </div>
         <!-- <div class="label">
          <span class="phone">邮箱: </span>
          <div>
             WS@wosaitech.com
          </div>
           
        </div> -->
         <div class="label">
          <span class="phone">公司地址: </span>
          <div>
            浙江省杭州市西湖区优盘时代大厦
          </div>
           
        </div>
 
      </div>
     
    </div>
    <div class="bottom">
        <div>
            Copyright © 2014-2022 浙江登沃智能科技有限公司
        </div>
        <div>
            <span>
                All Rights Reserved
            </span>
            <span
        > 
        <a href="https://beian.miit.gov.cn" target="_blank"
          >浙ICP备15001708号</a>
        </span
      >
        </div>
      <!-- <span
        > 
        <a href="https://beian.miit.gov.cn" target="_blank"
          >浙ICP备15001708号</a>
        </span
      > -->
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      phif: false,
      wxif: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    phShow() {
      this.phif = true;
    },
    phMouseout() {
      this.phif = false;
    },
    wxShow() {
      this.wxif = true;
    },
    wxMouseout() {
      this.wxif = false;
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.const {
  width: 100%;
   
  display: flex;
  
  background-color: #2c445c;
  box-sizing: border-box;
  color: white;

  .text {
    font-size: 16px;
    color: white;
   
    
    padding: 10px;
    .text-title {
      
      font-size: 20px;
      font-weight: 600;
     
      color: white;
    }
    .label {
      margin-top: 10px;
      div {
        margin: 5px 0;
      }
      
    }
    .line {
      width: 1px;
      height: 15px;
      background-color: #fff;
      margin-top: 17px;
    }

    .phoness {
      display: inline-block;
      width: 50px;
      margin-left: 60px;
    }
    .phones {
      display: inline-block;
      width: 80px;
      margin-left: 30px;
    }
    img {
      width: 30px;
      height: 30px;
      margin-right: 20px;
      position: relative;
      top: 10px;
    }
  }
  .code {
    margin-top: 35px;
    margin-right: 20%;
    // margin: 35px;
    // margin-left: 10%;
  }
}
.bottom {
  width: 100%;
//   height: 50px;
//   line-height: 50px;
  text-align: center;
  background-color: #2c445c;
  color: #CFCFCF;
  border-top: 1px solid white;
  padding: 10px 0;
  div {
    span {
        a{
            color: #CFCFCF;
            text-decoration: none;
        }
    }
  }
}
.wx,
.ph {
  position: relative;
  top: 10px;
  cursor: pointer;
  display: inline-block;
  width: 30px;
  height: 30px;
}
.ph {
  margin-right: 20px;
  background: url("../assets/image/taolPh.png") no-repeat;
  background-size: 100% 100%;
}
.wx {
  background: url("../assets/image/tailWx.png") no-repeat;
  background-size: 100% 100%;
}
.phContact {
  position: absolute;
  top: -240%;
  left: -160%;
  width: 120px;
  height: 65px;
  font-size: 14px;
  color: #333333;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 5px 0;

  > div {
    width: 100%;
    font-weight: 600;
  }
}
</style>
