import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'vant/lib/index.css';
import vant from 'vant';

// import VueAMap from 'vue-amap' //vue 高德地图 看情况要还是不要(暂时不要)
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(vant);
// Vue.use(VueAMap);
Vue.use(ElementUI);
Vue.config.productionTip = false
// VueAMap.initAMapApiLoader({
//   key: 'bdb8280440852cc851665bf341892de8',
//   v: '1.4.15'
// });

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')