<template>
  <div class="top-box" v-show="btnFlag">
    <div class="wx" @mouseover="wxShow" @mouseout="wxMouseout"></div>
    <div class="ph" @mouseover="phShow" @mouseout="phMouseout"></div>
    <div class="top" @click="backTop"></div>
    <div class="phContact" v-if="phif">
      <div>咨询热线</div>
      <div> 0571-87073809</div>
    </div>
    <div class="wxcode" v-if="wxif">
      <img src="../assets/image/code.jpg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      scrollTop: "",
      btnFlag: false,
      phif: false,
      wxif: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    backTop() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },
    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      const that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 60) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
      // console.log(that.scrollTop);
    },
    phShow() {
      this.phif = true;
    },
    phMouseout() {
      this.phif = false;
    },
    wxShow() {
      this.wxif = true;
    },
    wxMouseout() {
      this.wxif = false;
    },
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script> 

<style scoped lang="scss">
.top-box {
  width: 70px;
  height: 182px;
  position: fixed;
  top: 55%;
  right: -0.5%;
  // background: #fff;
  // box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  div {
    width: 44px;
    height: 44px;
    margin: 10px auto;
    cursor: pointer;
  }
  .wx {
    background: url("../assets/image/wx.png") no-repeat;
  }
  .ph {
    background: url("../assets/image/taolPh.png") no-repeat;
  }
  .top {
    background: url("../assets/image/top.png") no-repeat;
  }
  .phContact {
    width: 120px;
    height: 65px;
    font-size: 14px;
    color: #333333;
    text-align: center;
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    position: absolute;
    top: 30%;
    left: -180%;
    div {
      width: 100%;
      height: 25%;
      font-weight: 600;
    }
  }
  .wxcode {
    width: 119px;
    height: 119px;
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    position: absolute;
    top: 0;
    left: -180%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>