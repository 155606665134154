<template>
  <div id="app">
    <!-- v-if用来判断  显示pc还是移动端的组件 -->
    <Title v-if="flag === 2" />
    <Mtitle v-if="flag === 1 && status === '1'"> </Mtitle>
    <router-view />
    <BackToTop v-if="flag === 2" />
    <MbackToTop v-if="flag === 1"></MbackToTop>
    <Tail v-if="flag === 2" />
    <Mtail v-if="flag === 1"></Mtail>
  </div>
</template>

<script>
import Vue from "vue";
Vue.prototype.setSessionItem = function (key, newVal) {
  // 创建 StorageEvent 事件
  let newStorageEvent = document.createEvent("StorageEvent");
  const storage = {
    setItem: function (k, val) {
      sessionStorage.setItem(k, val);

      // 初始化 StorageEvent 事件
      newStorageEvent.initStorageEvent(
        "setItem", // 事件别名
        false,
        false,
        k,
        null,
        val,
        null,
        null
      );

      // 派发事件
      window.dispatchEvent(newStorageEvent);
    },
  };
  return storage.setItem(key, newVal);
};
import Title from "@/components/title";
import Mtitle from "@/components/m_title";
import Tail from "@/components/tail";
import Mtail from "@/components/m_tail";
import BackToTop from "@/components/backToTop.vue";
import MbackToTop from "@/components/m_backToTop.vue";
export default {
  name: "App",
  components: { Title, Tail, BackToTop, Mtail, Mtitle, MbackToTop },
  data() {
    return {
      flag: null,
      status: sessionStorage.getItem("status") || "1",
    };
  },
  methods: {
    // 判断类型
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  mounted() {
    window.addEventListener("setItem", () => {
      this.status = sessionStorage.getItem("status");
    });
    //判断类型  跳转不同页面
    if (this._isMobile()) {
      this.flag = 1;
      if (window.sessionStorage.select === "1") {
        this.$router.push("/m_wisdomWater");
      } else if (window.sessionStorage.select === "2") {
        this.$router.push("/m_monitor");
      } else if (window.sessionStorage.select === "3") {
        this.$router.push("/m_sewage");
      } else {
        this.$router.replace("/m_home");
      }
    } else {
      this.flag = 2;
      this.$router.replace("/home");
    }
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
body {
  background-color: #f6f6f6;
}
.van-image__img,
.van-image-preview__image {
  background: #fff !important;
}
</style>
