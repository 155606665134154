<template>
  <div class="const">
    <div class="top-box">
      <div class="text">
        <div>专注水务行业智慧化建设</div>
        <div class="title">登沃智能</div>
      </div>
      
    </div>
    <div class="message">
      <img src="../../assets/image/jieshao.png" alt="" />
      <!-- <h2>COMPANY PROFILE</h2> -->
      <h2>浙江登沃智能科技有限公司</h2>
      <div class="info">
        <div class="page">
          浙江登沃智能科技有限公司，是集研发、设计、生产、营销、服务于一体的水处理物联网及水处理方案解决商的高科技公司。拥有完全自主知识产权的物联网智能终端和多项软件著作权，可通过手机APP实现对水处理设备的远程监控。公司致力于利用物联网技术为环境保护领域中分散式设施实现高效管理，提供一站式智能监测、控制及水处理运维管理的完整解决方案，助力传统环保企业的网络化、智能化提升。<br />
          公司长期专注于智慧水务相关技术的研究和应用，具有丰富的水处理项目经验，为众多水务公司提供了专业的智慧水务及管控平台系统服务，获得客户一致好评。公司深耕水务领域，积极推进先进水务技术的研发，并与浙江大学环境与资源学院和浙江工商大学环境科学与工程学院等多家院校和企业达成战略联盟。
        </div>
      </div>
      <div class="bottom">
        <div class="left">
          <div class="jie">数智治水，高效赋能</div>
          <!-- <span>THE NUMBER OF WISDOM TO CONTROL WATER,EFFICIENT EMPOWERMENT</span> -->
        </div>
       
      </div>
    </div>

    <div class="goods">
      <!-- <h2 class="one">CUSTOMER CASE</h2> -->
      <h2>产品与服务</h2>
      <div class="coreBusiness">
        <div
          class="box"
          v-for="item in businessList"
          :key="item.id"
          @click="go(item.id)"
        >
          <div class="box-title">{{ item.text }}</div>
          <!-- <div class="box-bottom">{{ item.english }}</div> -->
          <img :src="item.img" alt="" />
          <div class="more" @mousemove="getMove(item.id)" @mouseleave="getLeave">
            了解更多
            <div class="jiao"></div>
            <div class="jiaos" :class="{ active: currIndex === item.id }"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
 Vue.prototype.setSessionItem = function (key, newVal) {
  // 创建 StorageEvent 事件
  let newStorageEvent = document.createEvent("StorageEvent");
  const storage = {
    setItem: function (k, val) {
      sessionStorage.setItem(k, val);

      // 初始化 StorageEvent 事件
      newStorageEvent.initStorageEvent(
        "setItem", // 事件别名
        false,
        false,
        k,
        null,
        val,
        null,
        null
      );

      // 派发事件
      window.dispatchEvent(newStorageEvent);
    },
  };
  return storage.setItem(key, newVal);
};
 
export default {
  name: "home",
  props: {},
  components: {
    
  },
  data() {
    return {
      show:false,
      currIndex: "",

      businessList: [
        {
          id:1,
          text: "智慧供水",
          img: require("@/assets/image/img1.png"),
          english: "WISDOM WATER SUPPLY",
        },
        {
           id:2,
          text: "排污监测",
         img: require("@/assets/image/img3.png"),
          english: "EMISSION MONITORING",
        },
        {
           id:3,
          text: "污水处置",
          img: require("@/assets/image/img4.png"),
          english: "SEWAGE TREATMENT",
        },
        
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
       // 回到顶部
     getTop(){
   const a = document.createElement("a");
      a.href = "#";
      a.click();
    },
    getMove(index) {
      this.currIndex = index;
    },
    getLeave() {
      this.currIndex = "";
    },
    //跳转页面
    go(item) {
      this.getTop()
      if (item == 1) {
        this.$router.push("/wisdomWater");
          this.setSessionItem("select", item);
           
          
      }
      if (item == 2) {
        this.$router.push("/monitor");
          this.setSessionItem("select", item);
       
          
      }
      if (item == 3) {
        this.$router.push("/sewage");
         this.setSessionItem("select", item);
      }
       
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.const {
  width: 100%;
  box-sizing: border-box;
  .linkText {
    color: #1864e3;
    text-decoration: none;
  }
  .top-box {
    width: 100%;
    height: 625px;
    padding: 0 160px;
    box-sizing: border-box;
    font-size: 40px;
    color: #ffffff;
    background: url("../../assets/image/bg1.jpg") no-repeat;
    background-size: 100%;
    overflow: hidden;
    margin-top: 100px;
    .text {
      text-align: center;
      margin-top: 230px;
      div {
        margin: 13px 0;
      }
    }
    .more {
      width: 120px;
      height: 36px;
      font-size: 16px;
      text-align: center;
      line-height: 36px;
      margin-top: 40px;
      border: 1px solid #ffffff;
      border-radius: 20px;
      cursor: pointer;
    }
  }
  .message {
    padding: 30px  0 0 19%;
    position: relative;
    // margin-top: 50px;
    background-color: #fff;
    img {
      position: absolute;
      width: 550px;
      height: 702px;
      // top: 14.4%;
      bottom: 0;
      left: 60%;
    }
    h2 {
      color: #0762cb;
      margin-bottom: 10px;
    }
    .info {
      // height: 400px;
      margin-top: 50px;
      .page {
        width: 35%;
        line-height: 40px;
      }
    }
    .bottom {
      display: flex;
      margin-top: 50px;
      width: 100%;
      height: 182px;
      background-color: #0762cb;
      .left {
        margin: 50px 0 50px 100px;
        .jie {
          color: white;
          font-size: 30px;
          margin-bottom: 15px;
          letter-spacing: 5px;
        }
        span {
          color: #fff;
        }
      }
      .right {
        margin: auto 0 auto 70px;
        .more {
          width: 46px;
          height: 46px;
          border-radius: 50%;
          border: 1px solid #fff;
          text-align: center;
          line-height: 46px;
          color: white;
          cursor: pointer;
        }
      }
    }
  }
  .goods {
    background-color: #fff;
    padding-top: 50px;
    h2 {
      text-align: center;
      margin-bottom: 10px;
      letter-spacing: 5px;
    }
    .one {
      color: #b0b7bf;
    }
    .coreBusiness {
      display: flex;
      flex-wrap: wrap;
      padding: 50px 19%;
      text-align: center;
      .box {
        width: 32.6%;
        font-size: 16px;
        color: #1864e3;
        position: relative;
        cursor: pointer;
        img {
          width: 100%;
          margin-bottom: 10px;
        }
        .box-title {
          position: absolute;
          top: 25%;
          left: 13%;

          font-size: 24px;

          color: #ffffff;
        }
        .box-bottom {
          position: absolute;
          top: 40%;
          left: 13%;
          color: #ffffff;
        }
        .more {
          width: 100px;
          height: 36px;
          border: 1px solid #fff;
          text-align: center;
          line-height: 36px;
          position: absolute;
          top: 70%;
          left: 13%;
          opacity: 1;
          color: #ffffff;
          padding-right: 5px;
          .jiao {
            position: absolute;
            top: 35%;
            left: 85%;
            width: 0;
            height: 0;
            border: 6px solid transparent;
            border-left-color: white;
          }
          .active {
            position: absolute;
            top: 35%;
            left: 85%;
            width: 0;
            height: 0;
            border: 6px solid transparent;
            border-left-color: #039191;
          }
        }
        .more:hover {
          background-color: #fff;
          color: black;
        }
      }
      .box:nth-child(2),
      .box:nth-child(5),
      .box:nth-child(8) {
        margin: 0 1%;
      }
    }
  }
   
}
</style>
