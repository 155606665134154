import Vue from 'vue';
import Router from 'vue-router';
import Home from '../pages/home/index.vue'
import M_Home from '../pages/home/m_index.vue'
 
Vue.use(Router);
const routes = [
    {
        path: '/home',
        name: 'home',
        component: Home,//pc端  首页
    },
    {
        path: '/m_home',
        name: ' M_Home',
        component: M_Home,//移动端 首页
    },
    {
        path: '/sewage',
        name: '污水处理',
        component: () => import('../pages/products/sewage.vue')//pc端 污水处理
    },
    {
        path: '/wisdomWater',
        name: '智慧供水',
        component: () => import('../pages/products/wisdomWater.vue')//pc端 智慧供水
    },
    {
        path: '/monitor',
        name: '排污检测',
        component: () => import('../pages/products/monitor.vue')//pc端 排污检测
    },
    // 路由的 name不能一样  否则会有路由警告(Duplicate named routes definition)
    {
        path: '/m_sewage',
        name: '污水处理+""',
        component: () => import('../pages/products/m_sewage.vue') //移动端  污水处理
    },
    {
        path: '/m_wisdomWater',
        name: '智慧供水+""',
        component: () => import('../pages/products/m_wisdomWater.vue')//移动端  智慧供水
    },
    {
        path: '/m_monitor',
        name: '排污检测+""',
        component: () => import('../pages/products/m_monitor.vue') //移动端  排污检测
    },
]
export default new Router({
    routes,  

})
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}