<template>
  <div class="top-box">
    <div class="wx" @click="getWxShow"></div>
    <div class="ph" @click="getPhShow"></div>

    <div class="phContact" v-if="phif">
      <div>咨询热线</div>
      <div @click="getPhone" class="phone">0571-87073809</div>
    </div>
    <div class="wxcode" v-if="wxif">
      <img src="../assets/image/code.jpg" alt="" />
    </div>
  <van-image-preview v-model="preview" :images="images" @close="onChange">
    </van-image-preview>
    <!-- 看情况要还是不要 -->
    <!-- <van-dialog
      v-model="phone"
      message="确认拨打0571-87073809吗?"
      show-cancel-button
      @cancel="cancel"
      @confirm="contact"
      class="dialog"
    >
    </van-dialog> -->
  </div>
</template>

<script>
import Vue from "vue";
Vue.prototype.setSessionItem = function (key, newVal) {
  // 创建 StorageEvent 事件
  let newStorageEvent = document.createEvent("StorageEvent");
  const storage = {
    setItem: function (k, val) {
      sessionStorage.setItem(k, val);

      // 初始化 StorageEvent 事件
      newStorageEvent.initStorageEvent(
        "setItem", // 事件别名
        false,
        false,
        k,
        null,
        val,
        null,
        null
      );

      // 派发事件
      window.dispatchEvent(newStorageEvent);
    },
  };
  return storage.setItem(key, newVal);
};
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      status:sessionStorage.getItem("status") || '1',
      phone: false,
      scrollTop: "",
      btnFlag: false,
      phif: false,
      wxif: false,
      images:[require('../assets/image/code.jpg')],
      preview:false
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 拨打电话
    getPhone() {
        const a = document.createElement("a");
      a.href = "tel:0571-87073809";
      a.click();
      this.phone = false;
    },
    cancel() {
      this.phone = false;
    },
    // contact() {
    //   const a = document.createElement("a");
    //   a.href = "tel:0571-87073809";
    //   a.click();
    //   this.phone = false;
    // },
    getWxShow() {
       
      this.preview=!this.preview
       
         this.setSessionItem("status", '2');
     
    },
    onChange(){
 this.setSessionItem("status", '1');
    },
    getPhShow() {
      let that = this;
      that.phif = !that.phif;
      if (that.phif) {
        setTimeout(() => {
          that.phif = false;
        }, 5000);
      }
    },
  },
  created() {},
  mounted() {
    window.addEventListener("setItem", () => {
      this.status = sessionStorage.getItem("status");
    });
  },
  destroyed() {
   
  },
};
</script>

<style scoped lang="scss">
 
.top-box {
  // width: 70px;

  position: fixed;
  bottom: 5%;
  right: 0;

  .wx {
    width: 40px;
    height: 40px;
    margin: 10px auto;
    cursor: pointer;
    background: url("../assets/image/mwx.png") no-repeat;
  }
  .ph {
    width: 40px;
    height: 40px;
    margin: 10px auto;
    cursor: pointer;
    background: url("../assets/image/mtaolPh.png") no-repeat;
  }

  .phContact {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 120px;
    height: 55px;
    font-size: 14px;
    color: #333333;
    text-align: center;
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    position: absolute;
    top: 50%;
    // left: -180%;
    right: 105%;
    div {
      width: 100%;
      height: 35%;
      font-weight: 600;
    }
    .phone {
      color: blue;
    }
  }
  .wxcode {
    width: 119px;
    height: 119px;
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    position: absolute;
    top: 0;
    left: -180%;
     img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
