<template>
  <div class="box">
    <div class="const">
      <div class="text">
        <div class="text-title">商业合作 :</div>
        <div class="label">
          <span class="phone">电话: 0571-87073809</span>
          <!-- <span>邮箱: WS@wosaitech.com</span> -->
          <!-- <div class="line"></div>
          <span>首页</span>
          <span>智能供水</span>
          <span>排水监测</span>
          <span>污水处理</span> -->
          <!-- <span>合作伙伴</span> -->
        </div>

        <!-- <br /> -->
        <div style="padding-bottom: 20px">
          <span class="address">公司地址：浙江省杭州市西湖区优盘时代大厦</span>
          <!-- <span style="margin: 0 2%"
          >Copyright © 2014-2022 杭州沃赛科技有限公司 All Rights Reserved
          <a href="https://beian.miit.gov.cn" target="_blank"
            >浙ICP备15001708号</a
          ></span
        >
        <div class="ph" @mouseenter="phShow" @mouseleave="phMouseout">
          <div class="phContact" v-if="phif">
            <div>咨询热线</div>
            <div> 0571-87073809</div>
          </div>
        </div>
        <div @mouseenter="wxShow" @mouseleave="wxMouseout" class="wx">
          <div class="wxcode" v-if="wxif"></div>
        </div> -->
        </div>
      </div>
      <div class="code">
        <img src="../assets/image/code.jpg" alt="" />
      </div>
    </div>
    <div class="bottom">
      <a href="https://beian.miit.gov.cn" target="_blank"
        >Copyright © 2014-2022 浙江登沃智能科技有限公司 All Rights Reserved
        <!-- <a href="https://beian.miit.gov.cn" target="_blank"
          >浙ICP备15001708号</a> -->
        </a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      phif: false,
      wxif: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    phShow() {
      this.phif = true;
    },
    phMouseout() {
      this.phif = false;
    },
    wxShow() {
      this.wxif = true;
    },
    wxMouseout() {
      this.wxif = false;
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.const {
  width: 100%;
  height: 190px;
  display: flex;
  // margin-top: 50px;
  padding-left: 360px;
  background-color: #2c445c;
  box-sizing: border-box;
  color: white;

  .text {
    font-size: 16px;
    color: white;
    padding-top: 30px;
    width: 100%;
    .text-title {
      width: 150px;
      height: 42px;
      font-size: 25px;
      font-weight: 600;
      // margin-bottom: 10px;
      color: white;
    }
    .label {
      display: flex;
      .phone {
        display: inline-block;
        width: 180px;
        margin: 14px 0;
      }
      span {
        margin: 13px 25px 0;
      }
    }
    .line {
      width: 1px;
      height: 15px;
      background-color: #fff;
      margin-top: 17px;
    }

    .phoness {
      display: inline-block;
      width: 50px;
      margin-left: 60px;
    }
    .phones {
      display: inline-block;
      width: 80px;
      margin-left: 30px;
    }
    img {
      width: 30px;
      height: 30px;
      margin-right: 20px;
      position: relative;
      top: 10px;
    }
  }
  .code {
    margin-top: 35px;
    margin-right: 20%;
    img {
      width: 119px;
    height: 119px;
    }
    // margin: 35px;
    // margin-left: 10%;
  }
}
.bottom {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: #1e3348;
  color: #fff;
  a {
    color: white;
  }
}
.wx,
.ph {
  position: relative;
  top: 10px;
  cursor: pointer;
  display: inline-block;
  width: 30px;
  height: 30px;
}
.ph {
  margin-right: 20px;
  background: url("../assets/image/taolPh.png") no-repeat;
  background-size: 100% 100%;
}
.wx {
  background: url("../assets/image/tailWx.png") no-repeat;
  background-size: 100% 100%;
}
.phContact {
  position: absolute;
  top: -240%;
  left: -160%;
  width: 120px;
  height: 65px;
  font-size: 14px;
  color: #333333;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 5px 0;

  > div {
    width: 100%;
    font-weight: 600;
  }
}
 
</style>
