<template>
  <div class="box">
<div class="top">
 <img src="../assets/image/click.png" alt="" @click="getClick">
</div>
<!-- 弹出层 -->
<van-popup v-model="show" position="top" :style="{ height: '37%' }">
  <div class="jump">
    <div class="son" v-for="item in toList" :key="item.id" @click="go(item.id)">
      {{item.name}}
    </div>
  </div>
</van-popup>

  </div>
</template>

<script>
import Vue from "vue";
Vue.prototype.setSessionItem = function (key, newVal) {
  // 创建 StorageEvent 事件
  let newStorageEvent = document.createEvent("StorageEvent");
  const storage = {
    setItem: function (k, val) {
      sessionStorage.setItem(k, val);

      // 初始化 StorageEvent 事件
      newStorageEvent.initStorageEvent(
        "setItem", // 事件别名
        false,
        false,
        k,
        null,
        val,
        null,
        null
      );

      // 派发事件
      window.dispatchEvent(newStorageEvent);
    },
  };
  return storage.setItem(key, newVal);
};
export default {
  data() {
    return {
      show:false,
      toList:[
        {name:'首页',id:0},
        {name:'智慧供水',id:1},
           {name:'排水监测',id:2},
            {name:'污水处理',id:3},
            
      ],
       select:sessionStorage.getItem("select") || 0,
        status:sessionStorage.getItem("status") || '1',
    };
  },
  methods: {
    // 回到顶部
     getTop(){
   const a = document.createElement("a");
      a.href = "#";
      a.click();
    },
    getClick(){
       this.show=!this.show
    },
    // 跳转页面
    go(id){
      this.setSessionItem('status','1')
      this.getTop()
      this.show=false
  if(id===0){
  this.$router.push("/m_home")
   this.setSessionItem("select", id);
  }else  if(id===1){
 this.$router.push("/m_wisdomWater")
  this.setSessionItem("select", id);
  }else if(id===2){
 this.$router.push("/m_monitor")
  this.setSessionItem("select", id);
  }else if(id===3){
 this.$router.push("/m_sewage")
  this.setSessionItem("select", id);
  }
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
      // 添加对 sessionStorage 的监听
    window.addEventListener("setItem", () => {
      this.select = sessionStorage.getItem("select");
       this.status = sessionStorage.getItem("status");
    });
  },
};
</script>
<style scoped lang="scss">
 
.top {
     position: fixed;
  top: 0;
  width: 100%;
  height: 49px;
    background: url("../assets/image/mlogo.png");
    background-size: 100% 100%;
    text-align: right;
    z-index: 2100;
    img {
      width: 49px;
      height: 49px;
    }
}
.jump {
  padding: 40px 10px 0 10px;
  .son {
    padding: 10px 0;
font-size: 17px;
font-weight: bold;
border-bottom: 1px solid #D5D5D5;
margin: 10px 0;

  }
}
</style>
